import React from 'react'

function TermsCondition() {
  return (
    <div className='d-flex justify-content-center flex-column align-items-center'>
        <div className='w-75 '>
        <h4>Payment Terms</h4>
        <b>
        Payments terms  are linked to progress of construction, evenly spread across construction period.
Illustrated for G+1 (Structure) of 1200 Sft Plot size.

        </b>
        <ul>
  <li>On signing of contract (as advance) : 5% </li>
  <li>Upon delivery of steel and cement at site: 5%</li>
  <li>Upon completion of Plinth : 10% </li>
  <li>Upon completion of Slab-1: 10% </li>
  <li>
  Upon completion of Slab-2: 10% 
  </li>
  <li>
  Upon Completion of Brick Work: 15%
  </li>
  <li>Upon Completion of Plaster Work: 15%</li>
  <li>Upon Completion of Tiling Work: 15%</li>
  <li>Upon Completion of Painting: 10%</li>
  <li>On Possession: 5 %</li>
</ul>
        </div>

        <div className='w-75 mb-2'>
            <h4>Terms & Conditions</h4>
            <ul>
                <li>
                Pick and choose customized option available to match your personality and lifestyle.
                </li>
                <li>
                Final Price may vary depending upon(location, plot/site conditions, applicable structural design and final chosen finishing items/features/add-ons)
                </li>
                <li>
                Products can be customized as per customer needs/budget and requirements.
                </li>
                <li>
                The above rates are applicable for minimum 1500 sqft. built up area and plots located within 35 Kmrs radius Bangalore. For other cities/metros, we shall give you the exact package pricing upon request.
                </li>
                <li>
                Bangalore and Mysore  Suburbs - Delight @1750 , Grand @2000 &  Iconic @ 2500 per sqft.
                </li>
                <li>
                2 year warranty on construction defects on all Talhar Construction Packages
                </li>
            </ul>
        </div>
       
    </div>
  )
}

export default TermsCondition