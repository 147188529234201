import React, { useEffect, useState } from "react";
import "../Styles/Home.css";
import Carousel from "react-bootstrap/Carousel";
import { Container, Modal, NavItem } from "react-bootstrap";
import { Row, Card, Button } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import Aos from "aos";
import axios from "axios";
import { FaArrowRightLong } from "react-icons/fa6";
import { ImQuotesLeft } from "react-icons/im";
import { FaSquareWhatsapp } from "react-icons/fa6";
import { TbListSearch } from "react-icons/tb";
import { MdMapsHomeWork } from "react-icons/md";
import { TbGitPullRequestClosed } from "react-icons/tb";
import parse from "html-react-parser";

const Home = () => {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const options1 = {
    loop: true,
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000, // Adjust the timing as needed
    smartSpeed: 500,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    Aos.init();
    // window.scroll(0, 0)
  });

  const [ViewMore1, setViewMore1] = useState(false); //About us
  const [ViewMore4, setViewMore4] = useState(false); //Our Services
  const [ViewMore3, setViewMore3] = useState(false); //what Client Say
  // const [ViewMore5, setViewMore5] = useState(false);

  // Get Method
  const [data, setdata] = useState([]);
  const getBannerData = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/banner/getbanner"
      );
      if (res.status === 200) {
        setdata(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      alert("Failed to fetch banners");
    }
  };

  const [ABoutUsDatat, setABoutUsDatat] = useState([]);
  const getAboutus = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/webmanagement/getaboutusdetails"
      );
      if (res.status === 200) {
        setABoutUsDatat(res.data.status);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      alert("Failed to fetch banners");
    }
  };

  //get method
  const [partnersimg, setPartnersimg] = useState([]);

  const getData = async () => {
    try {
      let res = await axios.get(
        "https://talharconstructions.in/api/partners/getpartner"
      );
      if (res.status === 200) {
        setPartnersimg(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [viewMoreStates, setViewMoreStates] = useState(
    Array(partnersimg?.length).fill(false)
  );

  const handleViewMoreToggle = (index) => {
    const updatedStates = [...viewMoreStates];
    updatedStates[index] = !updatedStates[index];
    setViewMoreStates(updatedStates);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getBannerData();
    getAboutus();
  }, []);

  //Our services
  //Get Method

  const [servicesdata, setServicesdata] = useState([]);

  const getServicesdata = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/services/getservice"
      );
      if (res.status === 200) {
        setServicesdata(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      alert("Failed to fetch banners");
    }
  };

  useEffect(() => {
    getServicesdata();
  }, []);

  // What Client Say
  //get method
  const [clientSay, setClientSay] = useState([]);

  const getClient = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/webmanagement/getwhatclientsaysdetails"
      );
      if (res.status === 200) {
        setClientSay(res.data.success); // Ensure the key matches the response
      }
    } catch (error) {
      console.error("Error fetching client details:", error);
      alert("Failed to fetch client details");
    }
  };

  useEffect(() => {
    getClient();
  }, []);

  // Why Choose us
  const ITEMS_PER_PAGE = 4;
  const [ourteamImg, setOurTeam] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // Calculate the indices of the items to display based on the current page
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = ourteamImg.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(ourteamImg.length / ITEMS_PER_PAGE);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [whyChoose, setWhyChoose] = useState([]);
  console.log("why choose us",whyChoose)

  const cleanedHtmlContent5 = whyChoose?.[0]?.chooseText?.replace(
    /<\/?p>/g,
    ""
  );

  const getTeam = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/webmanagement/getourteamdetails"
      );
      if (res.status === 200) {
        setOurTeam(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      alert("Failed to fetch banners");
    }
  };

  useEffect(() => {
    getTeam();
  }, []);

  const getAchivement = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/webmanagement/getachievementdetails"
      );
      if (res.status === 200) {
        setWhyChoose(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching achievement details:", error);
      alert("Failed to fetch achievement details");
    }
  };

  useEffect(() => {
    getAchivement();
  }, []);

  // Read More
  const [showModal, setShowModal] = useState(false);
  const [currentDescription, setCurrentDescription] = useState("");

  const handleReadMore = (description) => {
    setCurrentDescription(description);
    setShowModal(true);
  };

  const truncateText = (text) => {
    const maxLength = 300; // Approximate character limit for three lines
    return text && text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  return (
    <div >
      <div className="d-flex justify-content-center">

      <div className='cariuseldiv'>
        <Carousel>
          {data?.map((item) => {
            return (
              <Carousel.Item >
                <img
                  src={`https://talharconstructions.in/Bannerimage/${item?.bannerimg}`}
                  class="slider-img"
                  alt="..."
                />
                <Carousel.Caption  className="carouselcaption">
                  <h1 className="carousel-style" data-aos="zoom-in-left" data-aos-offset='200' data-aos-duration='1600'>{item?.bannertext.toUpperCase()}</h1>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>

      </div>
      <div className="fixed-icon">
        <a
          href="https://wa.me/8310330060" // Replace with the correct phone number
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Chat with us on WhatsApp"
        >
            {/* <img src="/Assets/whatsapp.jpg" alt="" style={{ height:"100px",width:"100px", color: "white" }} /> */}
          <FaSquareWhatsapp style={{ fontSize: "60px", color: "white" }} />
        </a>
      </div>
      <div>
        {/* Aout us Company */}
        <Container>
          <div className="row mt-4 mb-4">
            <div className="col-md-5">
              <div data-aos="zoom-in" data-aos-duration="3000">
                <img
                  className="about-img"
                  src={`https://talharconstructions.in/Aboutusimage/${ABoutUsDatat?.[0]?.aboutusimg}`}
                  alt=""
                />
              </div>
            </div>

            <div className="col-md-7">
              <div>
                <h4 className="main-title"> --- About us ---</h4>
                <h3 style={{ textAlign: "left",fontFamily:"Roboto"}}>
                  <b>We always think on your dream</b>
                </h3>
              </div>

              <p
                className="about-content"
                data-aos="fade-down"
                data-aos-duration="3000"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<div>${ABoutUsDatat?.[0]?.description}</div>`
               
                  }}
                />
                
                <div className="more-detailes">
                  <a href="/aboutus">
                    <Button
                      variant=""
                      style={{
                        backgroundColor: "#4FA9D8",
                        color: "white",
                      }}
                    >
                      Know More
                    </Button>
                  </a>
                </div>
              </p>
            </div>
          </div>
        </Container>
      </div>

      {/* Our Services */}
      <section className="section-contaner">
        <h4 style={{ color: "#4FA9D8" }}>----Our Services----</h4>
        {/* <h3 style={{ fontFamily:"Roboto" }}>
          <b>Our Services</b>
        </h3> */}
        <Container>
          <div className="row mt-4 mb-4 d-flex align-items-center justify-content-center m-auto ">
            {servicesdata?.length > 0 && (
              <OwlCarousel className="owl-theme " {...options1}>
                {servicesdata.map((item) => (
                  <div className="col-md-12  d-flex align-items-center justify-content-center" key={item.id}>
                    <div className="img-container">
                      <img
                        alt=""
                        className="img"
                        src={`https://talharconstructions.in/Partnersimage/${item?.serviceImage}`}
                      />
                      <div className="img-overlay">
                        <h6 style={{ color: "#4FA9D8"}}>
                          {item?.serviceName}
                        </h6>
                        <div
  className="custom-font-size"
  dangerouslySetInnerHTML={{
    __html: `<div>${item?.serviceDescription?.split("")
      .slice(0, 50)
      .join("")}...</div>`,
  }}
></div>
                        <div style={{ color: "white", textAlign: "center" }}>
                          <a href="/service">
                            <Button
                              style={{
                                backgroundColor: "#4FA9D8",
                                color: "white",
                                fontSize:"16px",
                                fontFamily:"Roboto"
                              }}
                            >
                              View More
                            </Button>
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <h1
                      className="img-caption-bottom"
                      style={{ fontSize: "30px" }}
                    >
                      <a
                        style={{ textDecoration: "none", color: "white" }}
                        href="/service"
                      >
                        {item?.serviceName}
                      </a> */}
                    {/* </h1> */}
                  </div>
                ))}
              </OwlCarousel>
            )}
          </div>
        </Container>
      </section>

      {/* Why choose us  */}
<div   style={{backgroundColor:"#4FA7D5"}} className="w-100">


      <section
        className="whychoose-bgblack"
        data-aos="zoom-in"
        data-aos-duration="2000"
      >
        <h4 style={{ color: "white" }}>--- Why Choose Us? ---</h4>
        <h3 style={{fontFamily:"Roboto"}}>
          <b>Strength Is Construction, Power In Precision</b>
        </h3>
 
      </section>
      <Container     >
        <div className="row me-0 mb-2  w-100 d-flex justify-content-around" 
              style={{backgroundColor:"#4FA7D5"}}>
          {whyChoose?.map((item) => {
            return (
              <div className="col-md-3 mb-2 " id="card-styyle">
                <div>
                  
                  <div>
                    <img
                      src={`https://talharconstructions.in/Aboutusimage/${item?.chooseImage}`}
                      alt={item?.chooseName}
                      style={{
                        borderRadius: "50%",
                        height: "100px",
                        width: "100px",
                      }}
                    />
                  </div>
                  <h5 style={{fontFamily:"Roboto"}}>{item?.chooseName}</h5>
                  <p className="whychoosecontent">
                    <div>
                      {parse(item?.chooseText)}
                    </div>
                    {/* <div
                   
                      dangerouslySetInnerHTML={{
                        __html: `<div >${cleanedHtmlContent5}</div>`,
                      }}
                    /> */}
                  </p>
                </div>
              </div>
            );
          })}
{/* 
          <div className="pagination gap-3 d-flex justify-content-center">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 5}
              </button>
            ))}
          </div> */}
        </div>
      </Container>
      </div>

      {/* How Process It Work */}
      <div
        style={{
          background: "rgba(211, 211, 211, 0.219)",
          padding: "15px",
          marginBottom: "20px",
        }}
      >
        {/* <h6 className="h1-txt" style={{ marginTop: "10px" }}>
          --- Work Process ---
        </h6> */}
          <h4 style={{ color: "#4FA9D8" }}>--- Work Process ---</h4>
          <h3 style={{fontFamily:"Roboto"}}>
          <b>How It Works</b>
        </h3>

        <div>
          <div
            className="row me-0 mt-2 mb-3"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <div className="col-md-3 mb-2 elipsescard">
              <TbListSearch style={{ fontSize: "60px", color: "#4798bd" }} />
              <h2>Project Research</h2>
             <span style={{fontSize:"16px"}}> "Comprehensive project research ensures effective planning, innovative solutions, and successful execution, meeting client needs with precision and excellence."</span>
            </div>

            <div className="col-md-3 mb-2 elipsescard"></div>

            <div className="col-md-3 mb-2 elipsescard">
              <MdMapsHomeWork style={{ fontSize: "60px", color: "#4798bd" }} />
              <h2>Work Start</h2>
              <span style={{fontSize:"16px"}}> We ensure that every project begins with thorough groundwork, ensuring the right foundation for success. From mobilizing the team to securing permits, we ensure a smooth transition from planning to execution, setting the stage for timely and efficient project completion.</span>
            </div>
            <div className="col-md-3 mb-2 elipsescard"></div>
          </div>
          <div className="row"></div>
        </div>
        <div>
          <div
            className="row me-0 mt-2 mb-3"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <div className="col-md-3 mb-2"></div>

            <div className="col-md-3 mb-2 elipsescard">
              <img
                style={{ height: "40px", width: "50px", color: "#4798bd" }}
                src="../Assets/workprocess1.png"
                alt=""
              />
              {/* <IoLogoDesignernews style={{ fontSize: "100px", color: "#0ba1f8" }} /> */}
              <h2>Design Build</h2>
              <span style={{fontSize:"16px"}}> Design-Build is an integrated approach where both design and construction services are provided by a single entity, ensuring streamlined communication, faster project delivery, and cost efficiency. By combining both processes, we can deliver high-quality results, minimize risks, and guarantee a smoother experience for our clients.</span>
            </div>
            <div className="col-md-3 mb-2"></div>

            <div className="col-md-3 mb-2 elipsescard">
              <TbGitPullRequestClosed
                style={{ fontSize: "60px", color: "#4798bd" }}
              />
              <h2>Finish Works</h2>
              <span style={{fontSize:"16px"}}>We focus on delivering flawless finish work, ensuring that every element meets our clients' expectations for both aesthetics and functionality. We prioritize attention to detail and quality to guarantee a superior finished product, ready for use and enjoyment.</span>
            </div>
          </div>
        </div>
      </div>

      {/* Project Done */}
      <Container>
        <div id="background">
          <div
            className="row mt-2 mb-3"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <div className="col-md-3 mb-2">
              <h1>10+</h1>
              <h4>Years of Experience </h4>
            </div>

            <div className="col-md-3 mb-2">
              <h1>100+</h1>
              <h4>Project Completed </h4>
            </div>

            <div className="col-md-3 mb-2">
              <h1>10+</h1>
              <h4>Ongoing Projects </h4>
            </div>

            <div className="col-md-3 mb-2">
              <h1 className="">50+</h1>
              <h4>Satisfied Customers</h4>
            </div>
          </div>
        </div>
      </Container>

      {/* latest project //Instead of partenrs */}
      <section className="section-contaners mt-4 mb-3">
        <div className="mb-5">
          <h4 className="main-title">--- Latest Projects ---</h4>
          {/* <h1 style={{ textAlign: "center" }}>
            <b>Crafting Quality Structures,Creating</b>
          </h1> */}
          
                <h4 style={{fontFamily:"Roboto"}}>
                We operates across multiple states, 
including <span style={{color:"#4798bd"}}>Karnataka</span>, <span style={{color:"#4798bd"}}>Andhra Pradesh</span>, <span style={{color:"#4798bd"}}>Telangana</span> , 
<span style={{color:"#4798bd"}}>Maharastra</span> , and <span style={{color:"#4798bd"}}>Tamil Nadu</span> .
        </h4>
          
        </div>
        <Container>
          <div className="row  d-flex align-items-center justify-content-center m-auto">
            {partnersimg?.length > 0 && (
              <OwlCarousel className="owl-theme" {...options1}>
                {partnersimg.map((item) => (
                  <div className="col-md-12 mb-2" key={item.id}>
                    <div className="img-container position-relative">
                      <img
                        src={`https://talharconstructions.in/Partnersimage/${item?.partnersimg}`}
                        alt="Partnerimage"
                        className="img"
                      />
                      <div className="img-overlay">
                        <h1 style={{ color: "#4FA9D8" }} className="img-caption">
                          {item.partnerName}
                        </h1>
                        <div
                          className="img-description"
                          style={{
                            width: "auto",
                            height: "100%",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `<div>${item?.partnersDescription}</div>`
                                .split("")
                                .slice(0, 30)
                                .join(""),
                            }}
                          />
                          <div
                            style={{
                              color: "white",
                              cursor: "pointer",
                              textAlign: "center",
                            }}
                          >
                            <a
                              href="/gallery"
                              style={{ color: "unset", textDecoration: "none" }}
                            >
                              <Button
                                variant=""
                                style={{
                                  backgroundColor: "#4FA9D8",
                                  color: "white",
                                
                                }}
                              >
                                View More
                              </Button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            )}
          </div>
        </Container>
      </section>

      {/* What Client Say?? */}
      <section className="section-container ">
        <div className="text-center">
          <h4 style={{ color: "white" }}>---Testimonial---</h4>
          <h3 style={{fontFamily:"Roboto"}}>
          <b>What Client Says</b>
        </h3>
        </div>

        <Container>
          <div
            className="row "
            style={{ background: "white", padding: "20px" }}
          >
            {clientSay?.length > 0 && (
              <OwlCarousel
                className="owl-theme"
                {...options1}
                style={{ color: "black" }}
              >
                {clientSay.map((item, index) => {
                  // Ensure clientDescription is defined before rendering
                  const description = item?.clientDescription || "";

                  // Remove unwanted characters such as double quotes or HTML entities
                  const cleanDescription = description.replace(/['"]/g, "");

                  return (
                    <div className="item" key={index}>
                      <div className="card testimonial-crd border-0">
                        <div className="row justify-content-center p-0">
                          <div className="d-flex res-testimonial mt-2">
                            <div className="mt-1 text-truncate-three-lines p-3">
                              {parse(truncateText(cleanDescription))}
                              {cleanDescription.length > 300 && (
                                <div
                                  className="read-more cursor-pointer"
                                  style={{
                                    textAlign: "left",
                                    color: "#4FA9D8",
                                  }}
                                  onClick={() =>
                                    handleReadMore(cleanDescription)
                                  }
                                >
                                  Read More
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="names-location mb-3">
                            <div
                              style={{ textAlign: "left", margin: "0 20px" }}
                            >
                              <b>{item.clientName}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            )}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Testimonial</Modal.Title>
              </Modal.Header>
              <Modal.Body>{parse(currentDescription)}</Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Container>
      </section>

      {/* More Contact detailes */}
      <Container fluid className="p-0 mt-3 mb-3">
        <div className="image-container">
          <img
            src="../Assets/freeConslt.jpg"
            alt="Background"
            className="img-fluid"
          />
          <div className="text-overlay">
            <h6>Get Consultation</h6>
            <h2> Get A Free Consultation</h2>
            <h2>
              Contact US <span style={{ color: "  orange" }}>!!</span>
            </h2>
            <div className="text-center mt-4">
              <Button variant="primary">
                {" "}
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="/contact"
                >
                  Contact Us
                </a>
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Home;
