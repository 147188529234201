import React, { useEffect, useState } from "react";
import "../Styles/About.css";
import { Container } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import parse from "html-react-parser";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Card from "react-bootstrap/Card";
import { ImQuotesLeft } from "react-icons/im";
import Aos from "aos";
import { FaSquareWhatsapp } from "react-icons/fa6";
import { GrUserExpert } from "react-icons/gr";
import { GiTimeTrap } from "react-icons/gi";
import Carousel from "react-bootstrap/Carousel";
import { TbListSearch } from "react-icons/tb";
import { MdMapsHomeWork } from "react-icons/md";
import { IoLogoDesignernews } from "react-icons/io5";
import { TbGitPullRequestClosed } from "react-icons/tb";
import axios from "axios";
// import parse from 'html-react-parser';
const ITEMS_PER_PAGE = 10;
const About = () => {
  const [activeTab, setActiveTab] = useState("mission");

  // Map of images based on active tab
  const images = {
    mission: "../Assets/mission.avif",
    vision: "../Assets/vision.webp",
  };
  const options = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  useEffect(() => {
    Aos.init();
    // window.scroll(0, 0);
  });

  const [ViewMore6, setViewMore6] = useState(false);

  const [ABoutUsDatat, setABoutUsDatat] = useState([]);
  const getAboutus = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/webmanagement/getaboutusdetails"
      );
      if (res.status === 200) {
        setABoutUsDatat(res.data.status);
        console.log("dffd",res.data.status)
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      alert("Failed to fetch banners");
    }
  };

  useEffect(() => {
    getAboutus();
  }, []);

  const cleanedHtmlContent4 = ABoutUsDatat?.[0]?.visionDescription?.replace(
    /<\/?p>/g,
    ""
  );

  const [ourteamImg, setOurTeam] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const getTeam = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/webmanagement/getourteamdetails"
      );
      if (res.status === 200) {
        setOurTeam(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      alert("Failed to fetch banners");
    }
  };

  useEffect(() => {
    getTeam();
  }, []);

  // Calculate the indices of the items to display based on the current page
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = ourteamImg.slice(indexOfFirstItem, indexOfLastItem);
  console.log("ourteam",currentItems)

  // Calculate total pages
  const totalPages = Math.ceil(ourteamImg.length / ITEMS_PER_PAGE);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // console.log("our team", ourteamImg);

  // Why Choose Us??
  //get method

  const [whyChoose, setWhyChoose] = useState([]);

  const cleanedHtmlContent5 = whyChoose?.[0]?.chooseText?.replace(
    /<\/?p>/g,
    ""
  );

  // const htmlContent4 = whyChoose?.[0]?.chooseText;

  const getAchivement = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/webmanagement/getachievementdetails"
      );
      if (res.status === 200) {
        setWhyChoose(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching achievement details:", error);
      alert("Failed to fetch achievement details");
    }
  };

  useEffect(() => {
    getAchivement();
  }, []);
  // const htmlContent3 = whyChoose?.[0]?.chooseText;

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(true);
const [isDescription1Expanded, setIsDescription1Expanded] = useState(true);
const [isDescription2Expanded, setIsDescription2Expanded] = useState(true);
const [isMissionExpanded, setIsMissionExpanded] = useState(true);
const [isVisionExpanded, setIsVisionExpanded] = useState(true);

const CHAR_LIMIT = 200;
 // description
const description = ABoutUsDatat?.[0]?.description || "";
const truncatedDescription =
  description.length > CHAR_LIMIT
    ? `${description.slice(0, CHAR_LIMIT)}...`
    : description;

const contentToDisplay = isDescriptionExpanded ? description : truncatedDescription;

// description1
const description1 = ABoutUsDatat?.[0]?.description1 || "";
const truncatedDescription1 =
  description1.length > CHAR_LIMIT
    ? `${description1.slice(0, CHAR_LIMIT)}...`
    : description1;

const contentToDisplay1 = isDescription1Expanded ? description1 : truncatedDescription1;

// description2
const description2 = ABoutUsDatat?.[0]?.description2 || "";
const truncatedDescription2 =
  description2.length > CHAR_LIMIT
    ? `${description2.slice(0, CHAR_LIMIT)}...`
    : description2;

const contentToDisplay2 = isDescription2Expanded ? description2 : truncatedDescription2;

// mission Description
const missionDescription = ABoutUsDatat?.[0]?.missionDescription || "";
const truncatedDescription3 =
  missionDescription.length > CHAR_LIMIT
    ? `${missionDescription.slice(0, CHAR_LIMIT)}...`
    : missionDescription;

const contentToDisplay3 = isMissionExpanded ? missionDescription : truncatedDescription3;

// vision Description
const visionDescription = ABoutUsDatat?.[0]?.visionDescription || "";
const truncatedDescription4 =
  visionDescription.length > CHAR_LIMIT
    ? `${visionDescription.slice(0, CHAR_LIMIT)}...`
    : visionDescription;

const contentToDisplay4 = isVisionExpanded ? visionDescription : truncatedDescription4;

  return (
    <div>
      <div className="aboutus-container">
        <div className="image-wrapper">
          <img
            className="aboutus-img"
            src="../Assets/aboutus.jpg"
            alt="about"
          />
          <div className="text-overlay">
            <h1>About Us</h1>
          </div>
          <div className="link-about">
            <a className="home" href="/">
              Home
            </a>
            /<a href="#">About Us</a>
          </div>
        </div>
        <div className="fixed-icon">
          <a
            href="https://wa.me/8310330060" // Replace with the correct phone number
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Chat with us on WhatsApp"
          >
            <FaSquareWhatsapp style={{ fontSize: "30px", color: "white" }} />
          </a>
        </div>
      </div>

     {/* About Us Company.. */}
<Container>
  <div className="row me-0 mt-5 mb-5">
    <div className="col-md-5">
      <div data-aos="zoom-in" data-aos-duration="3000">
        <img
          className="construction"
          src={`https://talharconstructions.in/Aboutusimage/${ABoutUsDatat?.[0]?.aboutusimg}`}
          alt="contruction"
        />
      </div>
    </div>

    <div className="col-md-7">
      <h5 className="h5-txt">About Us </h5>
      <div className="row ">
        <div className="col-md-12">
          <h2 className="h2-txt">{ABoutUsDatat?.[0]?.aboutustitle}</h2>
          <p data-aos="zoom-in" data-aos-duration="3000">
            {parse(contentToDisplay)}
          </p>
          {/* {description.length > CHAR_LIMIT && (
            <div
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
            >
              {isDescriptionExpanded ? "View less" : "View More"}
            </div>
          )} */}
        </div>
      </div>

      <div className="row">
        <div
          className="col-md-6 "
          id="box-padding"
          data-aos="flip-right"
          data-aos-duration="3000"
        >
          <h4 style={{color:"rgb(2, 104, 129)",fontFamily:"Roboto"}}>{parse(`<div>${ABoutUsDatat?.[0]?.subTitle1}</div>`)} </h4>
          <p data-aos="zoom-in" data-aos-duration="3000" style={{fontFamily:"Roboto"}}>
            {parse(contentToDisplay1)}
          </p>
          {/* {description1.length > CHAR_LIMIT && (
            <div
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => setIsDescription1Expanded(!isDescription1Expanded)}
            >
              {true ? "View less" : "View More"}
            </div>
          )} */}
        </div>
        <div
          className="col-md-6 "
          id="box-padding"
          data-aos="flip-up"
          data-aos-duration="3000"
        >
    <h4 style={{color:"rgb(2, 104, 129)",fontFamily:"Roboto"}}> {parse(`<div>${ABoutUsDatat?.[0]?.subTitle2}</div>`)} </h4>
    <p data-aos="zoom-in" data-aos-duration="3000"  style={{fontFamily:"Roboto"}}>
            {parse(contentToDisplay2)}
      
            {/* {description2.length > CHAR_LIMIT && (
              <div
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => setIsDescription2Expanded(!isDescription2Expanded)}
              >
                {isDescription2Expanded ? "View less" : "View More"}
              </div>
            )} */}
          </p>
        </div>
      </div>
    </div>
  </div>
</Container>

{/* mission and vision Desription */}
<Container>
  <div className="row mt-5 mb-5">
    <div className="col-md-6">
      <div data-aos="fade-down" data-aos-duration="3000">
        <Tabs
          defaultActiveKey="mission"
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={(k) => setActiveTab(k)} // Update active tab on selection
        >
          <Tab eventKey="mission" title={<span style={{ color: "rgb(2, 104, 129)" }}>Mission</span>}>
            <div
              className="paragraph"
              data-aos="fade-down"
              data-aos-duration="3000"
            >
              <p className="missiontext">
                {parse(contentToDisplay3)}</p>
                {/* {missionDescription.length > CHAR_LIMIT && (
                  <div
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => setIsMissionExpanded(!isMissionExpanded)}
                  >
                    {isMissionExpanded ? "View less" : "View More"}
                  </div>
                )} */}
            </div>
          </Tab>

          <Tab eventKey="vision" title={<span style={{ color: "rgb(2, 104, 129)" }}>Vision</span>}>
            <div
              className="paragraph"
              data-aos="fade-down"
              data-aos-duration="3000"
            >
              <p className="missiontext">
                {parse(contentToDisplay4)}</p>
                {/* {visionDescription.length > CHAR_LIMIT && (
                  <div
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => setIsVisionExpanded(!isVisionExpanded)}
                  >
                    {isVisionExpanded ? "View less" : "View More"}
                  </div>
                )} */}
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
    <div className="col-md-5">
      <div data-aos="flip-right" data-aos-duration="2000">
        <img
          style={{ height: "auto", width: "100%",height:"250px", borderRadius: "10px" }}
          src={images[activeTab]} 
          alt={activeTab}
        />
      </div>
    </div>
  </div>
</Container>


      {/* our Team */}
      <Container>
        <h1 className="h1-txt">Our Team</h1>
        <div
          className="row mt-4 mb-3"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          {currentItems.map((item) => (
            <div className="col-md-3 mb-2" key={item._id}>
              <div className="img-container2">
                <img
                  className="imgg"
                  src={`https://talharconstructions.in/Ourteamimage/${item?.ourteamimg}`}
                  alt=""
                />
         
                {/* <p style={{ textAlign: "center" }}>
                  {" "}
                  <b>
       
                  </b>{" "}
                  {item?.designation}
                </p> */}
              </div>
            <div className="temaname">
            <h6>{item?.ourteamName}</h6>
            </div>

            </div>
          ))}
        </div>

        {/* <div className="pagination gap-3 d-flex justify-content-center mb-2">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={currentPage === index + 1 ? "active" : ""}
            >
              {index + 1}
            </button>
          ))}
        </div> */}
      </Container>

    </div>
  );
};

export default About;
