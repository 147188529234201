import React, { useEffect, useState } from 'react'
import '../Styles/gallery.css'
import Aos from 'aos';
import { FaSquareWhatsapp } from "react-icons/fa6";
import axios from 'axios'

const Gallery = () => {
  useEffect(() => {
    Aos.init();
    // window.scroll(0, 0)
  })

  const [gallery, setGallery] = useState([])


  const getData = async () => {
    try {
      let res = await axios.get("https://talharconstructions.in/api/gallery/getgalleryimg")
      if (res.status === 200) {
        setGallery(res.data.success)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  const [video, SetVideo] = useState([])

  const getVideo = async () => {
    try {
      let res = await axios.get("https://talharconstructions.in/api/gallery/getgalleryvideo")
      if (res.status === 200) {
        SetVideo(res.data.success)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getVideo();
  }, [])
  // console.log("video", video);
  // console.log("gallery", gallery);
  return (
    <div>
     
       <div className="aboutus-container">
      {/* <div className="image-wrapper">
        <img className="aboutus-img" src="../Assets/servicebg.jpg" alt="about us image" />
        <h1 >Gallery</h1>
        <div className="link-about">
          <a href="/">Home</a>/
          <a href="/gallery">Gallery</a>
        </div>
      </div> */}
      
      <div className="image-wrapper">
  <img className="aboutus-img" src="../Assets/siler11.avif" alt="about us image" />
  <div className="text-overlay">
    <h1>Gallery</h1>
  </div>
  <div className="link-about">
    <a className="home" href="/">Home</a>/
    <a href="/gallery">Gallery</a>
  </div>
</div>
<div className="fixed-icon">
      <a
        href="https://wa.me/8310330060" // Replace with the correct phone number
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Chat with us on WhatsApp"
      >
        <FaSquareWhatsapp style={{ fontSize: "30px", color: "white" }} />
      </a>
    </div>
    </div>

      <div className="fluid-container">
      <div className="row mt-5 mb-5 ">
          {gallery?.map((item) => {
            return (
              <div className="col-md-3 mb-3 ">
                <div data-aos="zoom-in" data-aos-duration="3000">
              
                 <img src={`https://talharconstructions.in/Galleryimgimage/${item?.galleryimg}`} 
                 style={{width:"285px",height:"240px"
                  ,borderRadius:"8px"
                 }} />
                 </div>
                 <h6 style={{fontFamily:"Roboto"}}>{item?.gallerytitle}</h6>
              </div>
            )
          })}
          </div>
        <div className="row ">
          {video?.map((item) => {
            return (
              <div className="col-md-4 mb-3">
                <div data-aos="zoom-in" data-aos-duration="3000">
                <video controls autoplay muted style={{ width: "300px", height: "200px" }}>
             <source src={`https://talharconstructions.in/Galleryvideo/${item?.galleryvideo}`} type="video/mp4" />
             </video>
                </div>
                <h4>{item.videotext}</h4>
              </div>
            )
          })}


          {/* <div className="col-md-4 mb-3" data-aos-duration="3000">
                        <div data-aos="zoom-in" data-aos-duration="3000">
                            <img src="/Assets/interior1.jpg" alt="" className='img' />
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div data-aos="zoom-in" data-aos-duration="3000">
                            <img src="/Assets/construction5.jpg" alt="" className='img' />
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div data-aos="zoom-in" data-aos-duration="3000">
                            <img src="/Assets/interior2.jpg" alt="" className='img' />
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div data-aos="zoom-in" data-aos-duration="3000">
                            <img src="/Assets/construction6.jpg" alt="" className='img' />
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div data-aos="zoom-in" data-aos-duration="3000">
                            <img src="/Assets/interior3.jpg" alt="" className='img' />
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div data-aos="zoom-in" data-aos-duration="3000">
                            <img src="/Assets/construction7.jpg" alt="" className='img' />
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div data-aos="zoom-in" data-aos-duration="3000">
                            <img src="/Assets/interior4.jpg" alt="" className='img' />
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div data-aos="zoom-in" data-aos-duration="3000">
                            <img src="/Assets/construction8.jpg" alt="" className='img' />
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  )
}

export default Gallery