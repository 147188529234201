import React, { useEffect, useState } from "react";
import "../Styles/Packages.css";
import { Container, Table } from "react-bootstrap";
import axios from "axios";
import { IoIosArrowRoundForward } from "react-icons/io";
function Packages() {
  //  Get Category
  const [Category, setCategory] = useState([]);
  const getCategoryDetails = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/admin/getCategoryDetails"
      );
      if (res.status === 200) {
        setCategory(res.data.success);
      }
    } catch (error) {
      alert("Failed to fetch Ctegory Details");
    }
  };
  useEffect(() => {
    getCategoryDetails();
  }, []);
  console.log(Category, "Category");

  // Get SubCategory Details
  const [SubCategory, setSubCategory] = useState([]);
  const getSubCategory = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/admin/getDesignDetails"
      );
      if (res.status === 200) {
        setSubCategory(res.data.success);
      }
    } catch (error) {
      alert("Failed to fetch banners");
    }
  };
  useEffect(() => {
    getSubCategory();
  }, []);

  console.log(SubCategory, "SubCategory");

  //Get AddDescriptions
  const [AddDescription, setAddDescription] = useState([]);
  const getAddDescriptionDetails = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/admin/getbathroomdetails"
      );
      if (res.status === 200) {
        setAddDescription(res.data.success);
      }
    } catch (error) {
      alert("Failed to fetch banners");
    }
  };
  useEffect(() => {
    getAddDescriptionDetails([]);
  }, []);
  console.log(AddDescription, "AddDescription");

  return (
    <div>
      <section className="Package-Section">
        <h1>
          {" "}
          <b>
            Our construction packages
            <span> [Buildings] are:</span>
          </b>{" "}
        </h1>



        <Container>
          <Table responsive="sm" border="2" >
            <thead>
              <tr>
                <th colSpan="5" className="col-span"  >
                  TALHAR CONSTRUCTIONS PACKAGES
                </th>
              </tr>
              {SubCategory?.map((item, index) => {
                return (
                  <tr key={item?._id} >
                    <th rowSpan={2}>
                      <div>Sl.No</div>
               
                    </th>
                    <th style={{ fontSize: "20px" }} rowSpan={2}>
                      Description
                    </th>
                    <th rowSpan={2}>
                      <div style={{ fontSize: "20px" }}>Delight</div>(@ Rs.
                      {item?.delightPrice}-/ per Sft)
                    </th>
                    <th rowSpan={2}>
                      <div style={{ fontSize: "20px" }}>Grand</div>(@ Rs.
                      {item?.grandPrice}-/ per Sft)
                    </th>
                    <th rowSpan={2}>
                      <div style={{ fontSize: "20px" }}>Iconic</div>(@ Rs.
                      {item?.iconicPrice}-/ per Sft)
                    </th>
                  </tr>
                );
              })}
            </thead>
            <tbody></tbody>

            {/* Designs */}
            {Category?.map((item1, i) => {
              return (
                <>
                  <thead>
                    <tr>
                      <th colSpan="5" className="col-span1">
                        {item1?.categoryName}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {AddDescription?.filter(
                      (ele) => ele.selectedCategory === item1?.categoryName
                    )?.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <td>{++index}</td>
                          <td>
                            <div
                              style={{
                                width: "auto",
                                height: "100px",
                                overflow: "hidden",
                                overflowY: "scroll",
                              }}
                            >
                              {" "}
                              {item?.AddDescription1}
                            </div>
                          </td>
                          <td>
                          <div
                              style={{
                                width: "auto",
                                height: "100px",
                                overflow: "hidden",
                                overflowY: "scroll",
                              }}
                            >
                              {" "}
                              {item?.delight1}
                            </div>
                           </td>
                          <td>
                          <div
                              style={{
                                width: "auto",
                                height: "100px",
                                overflow: "hidden",
                                overflowY: "scroll",
                              }}
                            >
                              {" "}
                              {item?.grand1}
                            </div>
                           </td>
                          <td>
                          <div
                              style={{
                                width: "auto",
                                height: "100px",
                                overflow: "hidden",
                                overflowY: "scroll",
                              }}
                            >
                              {" "}
                              {item?.iconic1}
                            </div>
                           </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              );
            })}

            {/* Civil Works */}
            {/* <thead>
                            <tr>
                                <th colSpan="5" className="col-span1">CIVIL WORKS</th>
                            </tr>
                          
                        </thead> */}
            {/* <tbody>

                            <tr>
                                <td></td>
                                <td >Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                        </tbody> */}

            {/* Bathroom */}
            {/* <thead >
                            <tr>
                                <th colSpan="5" className="col-span1">BATHROOM</th>
                            </tr>
                            
                        </thead>
                        <tbody>

                            <tr>
                                <td></td>
                                <td >Description</td>
                                <td>Delight</td>
                                <td>Grand </td>
                                <td>Iconic</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                        </tbody> */}

            {/* Electricals */}
            {/* <thead >
                            <tr>
                                <th colSpan="5" className="col-span1">ELECTRICALS</th>
                            </tr>
                          
                        </thead>
                        <tbody>

                            <tr>
                                <td></td>
                                <td >Description</td>
                                <td>Delight</td>
                                <td>Grand </td>
                                <td>Iconic</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                        </tbody> */}

            {/* Doors & Windows */}
            {/* <thead>
                            <tr>
                                <th colSpan="5" className="col-span1">DOORS & WINDOWS </th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td></td>
                                <td >Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>

                        </tbody> */}

            {/* Kitchen */}
            {/* <thead>
                            <tr>
                                <th colSpan="5" className="col-span1">KITCHEN</th>
                            </tr>
                        
                        </thead>
                        <tbody>

                            <tr>
                                <td></td>
                                <td >Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                        </tbody> */}

            {/* Flooring And Painting */}
            {/* <thead>
                            <tr>
                                <th colSpan="5" className="col-span1"> FLOORING & PAINTING</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td></td>
                                <td >Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>

                        </tbody> */}

            {/* Add On Features */}
            {/* <thead>
                            <tr>
                                <th colSpan="5" className="col-span1"> ADD ON FEATURES</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td></td>
                                <td >Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Description</td>
                                <td>Delight</td>
                                <td>Grand</td>
                                <td>Iconic</td>
                            </tr>

                        </tbody> */}
          </Table>
        </Container>
      </section>
      
   <div className="d-flex justify-content-center">
   <div className='w-75 '>
        <h4>Payment Terms</h4>
        <b>
        Payments terms  are linked to progress of construction, evenly spread across construction period.
Illustrated for G+1 (Structure) of 1200 Sft Plot size.

        </b>
        <ul>
  <li>On signing of contract (as advance) : 5% </li>
  <li>Upon delivery of steel and cement at site: 5%</li>
  <li>Upon completion of Plinth : 10% </li>
  <li>Upon completion of Slab-1: 10% </li>
  <li>
  Upon completion of Slab-2: 10% 
  </li>
  <li>
  Upon Completion of Brick Work: 15%
  </li>
  <li>Upon Completion of Plaster Work: 15%</li>
  <li>Upon Completion of Tiling Work: 15%</li>
  <li>Upon Completion of Painting: 10%</li>
  <li>On Possession: 5 %</li>
</ul>
        </div>
   </div>

<div >
<div className='w-75 mb-2 ms-3 '>
            <h4>Terms and Conditions</h4>
            <ul>
                <li>
                <span><IoIosArrowRoundForward/></span>   Pick and choose customized option available to match your personality and lifestyle.
                </li>
                <li>
                <span><IoIosArrowRoundForward/></span> Final Price may vary depending upon(location, plot/site conditions, applicable structural design and final chosen finishing items/features/add-ons)
                </li>
                <li>
                <span><IoIosArrowRoundForward/></span>Products can be customized as per customer needs/budget and requirements.
                </li>
                <li>
                <span><IoIosArrowRoundForward/></span>The above rates are applicable for minimum 1500 sqft. built up area and plots located within 35 Kmrs radius Bangalore. For other cities/metros, we shall give you the exact package pricing upon request.
                </li>
                <li>
                <span><IoIosArrowRoundForward/></span> Bangalore and Mysore  Suburbs - Delight @1750 , Grand @2000 &  Iconic @ 2500 per sqft.
                </li>
                <li>
                <span><IoIosArrowRoundForward/></span> 2 year warranty on construction defects on all Talhar Construction Packages
                </li>
            </ul>
        </div>
</div>


    </div>
  );
}

export default Packages;
