import React, { useState, useEffect } from "react";
import "../Styles/Footer.css";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { MdPhone } from "react-icons/md";
import { TfiEmail } from "react-icons/tfi";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { Link } from "react-router-dom";

import { BiHome } from "react-icons/bi";
import { MdOutlinePhone } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import { FaInstagram } from "react-icons/fa";
import { LiaFacebook } from "react-icons/lia";
import { FaWhatsapp } from "react-icons/fa";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Container } from "react-bootstrap";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import axios from "axios";
import { IoIosArrowRoundForward } from "react-icons/io";

const Footer = () => {
  const [data, setdata] = useState([]);
  console.log(data, "sdjfduhgiudh");
  const getContactDetailes = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/contactus/getcontactusdetails"
      );
      console.log(res, "arpjeqpiffffff");
      if (res.status === 200) {
        setdata(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      alert("Failed to fetch banners");
    }
  };

  useEffect(() => {
    getContactDetailes();
  }, []);

  //Get Method
  const [data1, setData1] = useState([]);
  console.log("data1", data1);

  const getSocialMedia = async () => {
    try {
      let res = await axios.get(
        "https://talharconstructions.in/api/contactus/getsocialmediadetails"
      );
      if (res.status === 200) {
        setData1(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSocialMedia();
  }, []);

  const [ABoutUsDatat, setABoutUsDatat] = useState([]);
  const getAboutus = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/webmanagement/getaboutusdetails"
      );
      if (res.status === 200) {
        setABoutUsDatat(res.data.status);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      alert("Failed to fetch banners");
    }
  };

  useEffect(() => {
    getAboutus();
  }, []);

  

  const htmlContent = ABoutUsDatat?.[0]?.description;

 return (
    <div>
      <div style={{ borderTop: "1px solid lightgray" }}></div>

      <div style={{ backgroundColor: "rgba(49,106,131,255)", color: "white",height:'250px' }}>
        <div className="footer">
          <div className="second">
            <div className="contact">
            <div>
      <h4 style={{ color: 'white', }}>About Us</h4>
      <ul style={{ textAlign: 'left' }}>
        {/* <p>
          <b style={{ textAlign: 'left' }}>Talhar Constructions</b>
        </p> */}
        {ABoutUsDatat?.map((item, index) => {
        return (
            <div className="custom-button" key={index}>
              <a href="/aboutus" className="custom-button-link">
                <p style={{textAlign:"start"}}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<div >${htmlContent?.split("")
                        .slice(0, 200)
                        .join("")}...</div>`,
                    }}
                  />
                </p>
              </a>
             
            </div>
          );
        })}
      </ul>
    </div>
            </div>
          </div>

          <div className="second">
            <div className="contact">
              <h4>Quick Links</h4>
              <ul className="bhgdhg">
                <li>
                  <a
                    href="/aboutus"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <span><IoIosArrowRoundForward/></span> About us
                  </a>
                </li>
                <li>
                  <a
                    href="/service"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <span><IoIosArrowRoundForward/></span> Service
                  </a>
                </li>
                <li>
                  <a
                    href="/gallery"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <span><IoIosArrowRoundForward/></span> Gallery
                  </a>
                </li>
                <li>
                  <a
                    href="/contact"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <span><IoIosArrowRoundForward/></span> Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>

   
  
          <div className="second">
            {data?.map((item) => {
              return (
                <div className="contact">
                  <h4>Contact</h4>
                  <ul className="bhgdhg">
                    <li><span><MdPhone/></span> {item?.phoneNumber1}</li>
                    {/* <li>  www.talharconstructions</li> */}
                    <li> <span><TfiEmail/></span> {item?.email}</li>
                    <li>  <span><HiOutlineOfficeBuilding/></span> {item?.message}</li>
                  </ul>
                </div>
              );
            })}


          </div>

          <div className="second">
            <div className="contact">
              <h4>Follow Us</h4>
              {data1.map((item) => {
              return (
                <div className="social-media">
                  <a
                    href={item.facebookUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FaFacebookSquare
                      style={{ fontSize: "30px", color: "white" }}
                    />
                  </a>

                  <a
                    href={item.instagramUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagramSquare
                      style={{ fontSize: "30px", color: "white" }}
                    />
                  </a>

                  {/* <a
                    href={item.linkedinUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin style={{ fontSize: "30px", color: "white" }} />
                  </a> */}
                </div>
              );
            })}
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default Footer;
