import React, { useEffect, useState } from "react";
import "../Styles/service.css";
import Aos from "aos";
import { FaSquareWhatsapp } from "react-icons/fa6";
import axios from "axios";

const Service = () => {
  useEffect(() => {
    Aos.init();
    // window.scroll(0, 0)
  });

  // const [ViewMore, setViewMore] = useState (false);
  // const [ViewMore2, setViewMore2] = useState (false);

  const [servicesdata, setServicesdata] = useState([]);

  const getServicesdata = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/services/getservice"
      );
      if (res.status === 200) {
        setServicesdata(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      alert("Failed to fetch banners");
    }
  };
  const [viewMoreStates, setViewMoreStates] = useState(
    Array(servicesdata?.length).fill(false)
  );

  const handleViewMoreToggle = (index) => {
    const updatedStates = [...viewMoreStates];
    updatedStates[index] = !updatedStates[index];
    setViewMoreStates(updatedStates);
  };
  useEffect(() => {
    getServicesdata();
  }, []);
  // const htmlContent1 = servicesdata?.[0]?.serviceDescription;
  const CHAR_LIMIT = 200;
  return (
    <div>
      <div className="aboutus-container">
        {/* <div className="image-wrapper">
        <img className="aboutus-img" src="../Assets/servicebg.jpg" alt="about us image" />
        <h1 >Services US</h1>
        <div className="link-about">
          <a href="/">Home</a>/
          <a href="/service">Services</a>
        </div>
      </div> */}
        <div className="image-wrapper">
          <img
            className="aboutus-img"
            src="../Assets/servicebg.jpg"
            alt="about us image"
          />
          <div className="text-overlay">
            <h1>Services</h1>
          </div>
          <div className="link-about">
            <a className="home" href="/">
              Home
            </a>
            /<a href="/service">Services</a>
          </div>
        </div>

        <div className="fixed-icon">
          <a
            href="https://wa.me/8310330060" // Replace with the correct phone number
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Chat with us on WhatsApp"
          >
            <FaSquareWhatsapp style={{ fontSize: "30px", color: "white" }} />
          </a>
        </div>
      </div>

      <div className="container">
        <div className="text" data-aos="fade-down" data-aos-duration="3000">
          {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cum, itaque laboriosam. Porro inventore quos debitis itaque nostrum ipsum dolores nam quod? Ipsam aperiam vero libero exercitationem reprehenderit odio nam dolorum eos dolorem, explicabo vitae illo non magni nihil perspiciatis esse possimus placeat! Ipsa iure velit recusandae, atque earum nostrum, eaque corrupti ea beatae quod aliquid eum fugit maxime vel a ullam, itaque inventore soluta odio accusamus.</p> */}
        </div>
        {servicesdata?.map((item, i) => {
          const isViewMore = viewMoreStates[i];
          const truncatedDescription =
            item?.serviceDescription
          return (
            <div className="row mt-2 mb-2  d-flex justify-content-around" key={i}>
              {i % 2 === 0 ? (
                <>
                  <div className="col-md-5 mt-2 ">
                    <div data-aos="zoom-in" data-aos-duration="3000">
                      <img
                        src={`https://talharconstructions.in/Partnersimage/${item?.serviceImage}`}
                        alt="serviceImage"
                        style={{ width: "80%", height: "250px",borderRadius:'20px'}}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt-2 ">
                    <div data-aos="fade-down" data-aos-duration="3000">
                      <h1 className="ser-style">{item?.serviceName}</h1>
                      <p className="text1">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: isViewMore
                              ? `<div>${item?.serviceDescription}</div>`
                              : `<div>${truncatedDescription}</div>`,
                          }}
                        />
                        {/* {item?.serviceDescription?.length > CHAR_LIMIT && (
                          <div
                            style={{ color: "blue" }}
                            onClick={() => handleViewMoreToggle(i)}
                          >
                            {isViewMore ? "View less" : "View More"}
                          </div>
                        )} */}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-6 mt-2 ">
                    <div data-aos="fade-down" data-aos-duration="3000">
                      <h1 className="ser-style">{item?.serviceName}</h1>
                      <p className="text1">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: isViewMore
                              ? `<div>${item?.serviceDescription}</div>`
                              : `<div>${truncatedDescription}</div>`,
                          }}
                        />
                        {/* {item?.serviceDescription?.length > CHAR_LIMIT && (
                          <div
                            style={{ color: "blue" }}
                            onClick={() => handleViewMoreToggle(i)}
                          >
                            {isViewMore ? "View less" : "View More"}
                          </div>
                        )} */}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 mt-2 ">
                    <div data-aos="zoom-in" data-aos-duration="3000">
                      <img
                        src={`https://talharconstructions.in/Partnersimage/${item?.serviceImage}`}
                        alt="serviceImage"
                        style={{ width: "80%", height: "250px",borderRadius:'20px' }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        })}

        {/* <div className="row mt-2 mb-2">
          <div className="col-md-6">
            <div data-aos="fade-down" data-aos-duration="3000">
              <p className='text1'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nostrum cupiditate debitis 
                harum veritatis, ut repudiandae beatae consequuntur. Corporis, nesciunt accusamus? Lorem ipsum dolor sit
                 amet consectetur adipisicing elit. Magni commodi aperiam voluptate, aliquid fugiat inventore tempore, 
                 sequi odit obcaecati ex nihil nesciunt? Nam eum consequatur blanditiis odit aliquid quis inventore!</p>
            </div>
          </div>

          <div className="col-md-5">
            <div data-aos="zoom-in" data-aos-duration="3000">
              <img src="/Assets/img2.avif" alt="" style={{ width: "100%", height: 300 }} /></div>
          </div>

        </div>

        <div className="row mt-2 mb-2">

          <div className="col-md-5">
            <div data-aos="zoom-in" data-aos-duration="3000">
              <img src="/Assets/img3.jpg"
                alt="" style={{ width: "100%", height: 300 }}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div data-aos="fade-down" data-aos-duration="3000">
              <p className='text1'>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nostrum cupiditate debitis
                harum veritatis, ut repudiandae beatae consequuntur. Corporis, nesciunt accusamus? Lorem
                ipsum dolor, sit amet consectetur adipisicing elit. Est, exercitationem rem fuga nihil quo
                animi maiores. Modi cumque tenetur cum illo ipsa non temporibus vel odit dolorem, aliquam
                adipisci dignissimos.</p>
            </div>
          </div>
          </div> */}
      </div>
    </div>
  );
};

export default Service;
