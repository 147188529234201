import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import Footer from './components/Footer';
import About from './components/About';
import Service from './components/Service';
import Gallery from './components/Gallery';
import Contactus from './components/Contact';
import Header from './components/Header';
import AdminPanel from './components/Admin/AdminPanel';
import Dashboard from './components/Admin/Dashboard';
import Banner from './components/Admin/Banner';
import WebManagement from './components/Admin/WebManagement';
import AdminLogin from './components/Admin/AdminLogin';
import Services from './components/Admin/Services';
import ContactUs from './components/Admin/ContactUs';
import Galleries from './components/Admin/Gallery';
import Partners from './components/Admin/Partners';
import Enquiries from './components/Admin/Enquiries';
// import Package from './components/Admin/Package';
import Packages from './components/Packages';
import ConstructionPackages from './components/Admin/ConstructionPackages'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TermsCondition from './components/TermsCondition';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<><Header /><Home /><Footer /></>} />
          <Route path='/aboutus' element={<><Header /><About /><Footer /></>} />
          <Route path='/service' element={<><Header /><Service /><Footer /></>} />
          <Route path='/gallery' element={<><Header /><Gallery /><Footer /></>} />
          <Route path='/contact' element={<><Header /><Contactus /><Footer /></>} />
          <Route path='/packages' element={<><Header /><Packages /><Footer /></>} />
          <Route path='/termsandconditions' element={<><Header /><TermsCondition/><Footer /></>} />

          {/* ADMIN PANEL ROUTES */}
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/adminPanel" element={<AdminPanel />} />
          <Route path="/admin/dashboard" element={<AdminPanel><Dashboard /></AdminPanel>} />
          <Route path="/admin/banner" element={<AdminPanel><Banner /></AdminPanel>} />
          <Route path="/admin/services" element={<AdminPanel><Services /></AdminPanel>} />
          <Route path="/admin/gallery" element={<AdminPanel><Galleries /></AdminPanel>} />
          <Route path="/admin/contactus" element={<AdminPanel><ContactUs /></AdminPanel>} />
          <Route path="/admin/webmanagement" element={<AdminPanel><WebManagement /></AdminPanel>} />
          <Route path="/admin/partners" element={<AdminPanel><Partners /></AdminPanel>} />
          {/* <Route path="/admin/packages" element={<AdminPanel><Package /></AdminPanel>} /> */}
          <Route path="/admin/enquiries" element={<AdminPanel><Enquiries /></AdminPanel>} />
          <Route path="/admin/constructionPkackages" element={<AdminPanel><ConstructionPackages /></AdminPanel>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
