import React, { useState,useEffect } from "react";
import { Button, Row, Table, Modal } from "react-bootstrap";

import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import './Admin.css'
import axios from 'axios'


const ContactUs = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

 


  // condition 
 
  const [contactdetailes, setContactDetailes] = useState(true);
  const [socialmedia, setSocialMedia] = useState("");
  const [termsandcondition, setTermsCondition] = useState("");


  const validatePhoneNumber = (phoneNumber) => {
    // Phone number must start with 7, 8, or 9 and be exactly 10 digits long
    return /^[789]\d{9}$/.test(phoneNumber);
  };
  
  const validateEmail = (email) => {
    // Email must contain "@" and "." characters
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  
  
 //Post Method
//  const [name,setName]=useState ('')
 const [phoneNumber1, setPhoneNumber1] = useState('')
//  const [phoneNumber2, setPhoneNumber2] = useState('')
 const [email, setEmail] = useState('')
 const [message, setMessage] = useState('')
 
 //Post Method
 const AddContactDetailes = async () => {
    if( !phoneNumber1 || !email || !message.trim()){
      alert("All fields are required");
      return;
    }

  
    // if (!validatePhoneNumber(phoneNumber1)) {
    //   alert("Invalid phone number. It must start with 7, 8, or 9 and be exactly 10 digits long.");
    //   return;
    // }
  
    if (!validateEmail(email)) {
      alert("Invalid email format. It must contain '@' and '.' characters.");
      return;
    }

    
  try {
    const config = {
      url: "/postcontactusdetails",
      method: "post",
      baseURL: "https://talharconstructions.in/api/contactus",
      Headers: { "content-type": "application/json" },
      data:{
        // name:name,
      phoneNumber1: phoneNumber1,
      // phoneNumber2: phoneNumber2,
      email:email,
      message:message,
      }
    };
    let res = await axios(config)
    if (res.status === 200) {
      alert(res.data.success);
      handleClose();
      getContactDetailes();
      // setName("");
      setPhoneNumber1("")
      setEmail("")
      setMessage("")
    }
  }
  catch (error) {
    console.error('Error adding banner:', error);
    alert('Failed to add banner');
  }
}

 
 //Get Method
 const [data, setdata] = useState([]);
 console.log(data,"sdjfduhgiudh")
 const getContactDetailes = async () => {
   try {
     const res = await axios.get('https://talharconstructions.in/api/contactus/getcontactusdetails');
     console.log(res,"arpjeqpiffffff");
     if (res.status === 200) {
       setdata(res.data.success);
     }
   }
    catch (error) {
     console.error('Error fetching banners:', error);
     alert('Failed to fetch banners');
   }
 };

 useEffect(() => {
  getContactDetailes()
 }, [])


 //Delete Method
  const deleteContactdetailes = async (_id) => {
   try {
     let res = await axios.delete(`https://talharconstructions.in/api/contactus/deletecontactusdetails/${edit?._id}`)
     if (res.status === 200) {
       alert("Delete Successfully")
       handleClose2()
       getContactDetailes([])
     }
   } catch (error) {
     console.log(error)
   }
 }


 //Edit Banner
 const [edit, setEdit] = useState({})
 useEffect(() => {
 if(edit){
  // setName(edit?.name || "")
  setPhoneNumber1(edit?.phoneNumber1 || "")
  setEmail(edit?.email || "")
  setMessage(edit?.message || "")
 }
 }, [])

//  console.log(edit, "sditeeee")
 const editContactDetailes = async () => {

  if( !phoneNumber1 && !email && !message.trim()){
    alert("Please update any one field");
    return;
  }
  // if (phoneNumber1 && !validatePhoneNumber(phoneNumber1)) {
  //   alert("Invalid phone number. It must start with 7, 8, or 9 and be exactly 10 digits long.");
  //   return;
  // }

  if (email && !validateEmail(email)) {
    alert("Invalid email format. It must contain '@' and '.' characters.");
    return;
  }

 
  try {
     const config = {
       url: `/updatecontactusdetails/${edit?._id}`,
       method: "put",
       baseURL: "https://talharconstructions.in/api/contactus",
       Headers: { "content-type": "application/json" },
       data:{
        // name:name,
        phoneNumber1: phoneNumber1,
        // phoneNumber2: phoneNumber2,
        email:email,
        message:message,
        }
     };
     let res = await axios(config)
     if (res.status === 200) {
       alert(res.data.success);
       handleClose1();
       getContactDetailes();
       // after updated the inputfields will be clear
       setPhoneNumber1("")
      setEmail("")
      setMessage("")
     }

   } catch (error) {
     console.error('Error adding banner:', error);
     alert('Failed to add banner');
   }
 }

//<--------------------SocialMedia--------------->

//post method
const [whatsappUrl, setWatsAppUrl] = useState('')
const [facebookUrl,setFacebookUrl] = useState('')
const [instagramUrl, setInstagramUrl] = useState('')


const AddSocialMediaDetailes = async () => {
  if( !whatsappUrl || !facebookUrl || !instagramUrl.trim()){
    alert("All fields are rquired");
    return;
  }
  try {
    const config = {
      url: "/postsocialmediadetails",
      method: "post",
      baseURL: "https://talharconstructions.in/api/contactus",
      Headers: { "content-type": "application/json" },
      data:{
        whatsappUrl: whatsappUrl,
        facebookUrl: facebookUrl,
        instagramUrl:instagramUrl,
       
        }
    };

    axios(config).then((res) => {
      if (res.status === 200) {
        alert(res.data.success);
        handleClose(); 
        getSocialMedia();
        setWatsAppUrl('');
        setFacebookUrl('');
        setInstagramUrl('');
      }
    });

  } catch (error) {
    console.error('Error adding banner:', error);
    alert('Failed to add banner');
  }
};


//Get Method
const[data1,setData1] = useState([])
console.log("data1",data1)

const getSocialMedia = async () => {
   try {
     let res = await axios.get("https://talharconstructions.in/api/contactus/getsocialmediadetails")
     if (res.status === 200) {
      setData1(res.data.success)
     }
   } catch (error) {
     console.log(error)
   }
 }

 useEffect(() => {
  getSocialMedia();
 }, [])
 console.log(data1,"ojripejwpjop");


 //Edit Model
 console.log("edit",edit);
useEffect(() =>{
  if(edit){
    setWatsAppUrl(edit?.whatsappUrl || "");
    setFacebookUrl(edit?.facebookUrl || "");
    setInstagramUrl(edit?.instagramUrl || "")
  }
}, [])
 const editSocialMedia = async () => {

  
  if( !whatsappUrl && !facebookUrl && !instagramUrl.trim()){
    alert("Please update any one field");
    return;
  }

  try {
     const config = {
       url: `/updatesocialmediadetails/${edit?._id}`,
       method: "put",
       baseURL: "https://talharconstructions.in/api/contactus",
       Headers: { "content-type": "application/json" },
       data:{
        whatsappUrl: whatsappUrl,
        facebookUrl: facebookUrl,
        instagramUrl:instagramUrl,
  }
     };
     let res = await axios(config)
     if (res.status === 200) {
       alert(res.data.success);
       handleClose1();
       getSocialMedia();
        // after updated the inputfields will be clear
        setWatsAppUrl('');
        setFacebookUrl('');
        setInstagramUrl('');
     }

   } catch (error) {
     console.error('Error adding banner:', error);
     alert('Failed to add banner');
   }
 }
 //Delete Method
const deleteSocialMedia= async (_id) => {
   try {
     let res = await axios.delete(`https://talharconstructions.in/api/contactus/deletesocialmediadetails/${edit?._id}`)
     if (res.status === 200) {
       alert("Deleted Successfully")
       handleClose2()
       getSocialMedia([])
     }
   } catch (error) {
     console.log(error)
   }
 }

 return (
    <div>
      <Row>
        <div className='p-3 d-flex gap-3'>
         
          <Button variant="" style={{ background: "rgb(3, 146, 182) ", color: "white" }}
            onClick={() => {
              
              setContactDetailes(true);
              setTermsCondition(false)
              setSocialMedia(false);

            }}
          >Contact Details</Button>

          <Button variant="" style={{ background: "rgb(3, 146, 182)", color: "white" }} onClick={() => {
            
            setContactDetailes(false);
            setTermsCondition(false)
            setSocialMedia(true);
          }}>Social Media</Button>


        </div>
      </Row>

    
      {contactdetailes ? (<>
        <h1 className='h1-style'>Contact Details</h1>
        <div>
{data.length ? (
  ""
) : ( <Button className='add-buttons' onClick={handleShow}>
  +Add 
</Button>)}


        

        
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>Contact Details </Modal.Title>
          </Modal.Header>

          <Modal.Body>

          {/* <div class="mt-3">
              <label  >Name</label>
              <div>
                <input type="text"
                  placeholder='Name..'
                  style={{ width: "100%" }} 
                  value={name}
                  onChange={(e)=>setName(e.target.value)}/>
              </div>
            </div> */}

            <div class="mt-3">
              <label  >phoneNumber</label>
              <div>
                <input type="text"
                  placeholder='phoneNumber..'
                  style={{ width: "100%" }} 
                  value={phoneNumber1}
                  // maxLength={10}
                  onChange={(e)=>setPhoneNumber1(e.target.value)}/>
              </div>
            </div>
          

            {/* <div>
              <label htmlFor="">phoneNumber2</label>
              <div>
                <input type="text"
                  placeholder='phoneNumber2......'
                  style={{ width: "100%" }} 
                  value={phoneNumber2}
                  onChange={(e)=>setPhoneNumber2(e.target.value)} />
              </div>
            </div> */}

         
            <div>
              <label htmlFor="">Email</label>
              <div>
                <input type="email"
                  placeholder='email....'
                  style={{ width: "100%" }} 
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)} />
              </div>
            </div>
            <br />
            <div>
              <label htmlFor="">Address</label>
              <div>
                <input type="text"
                  placeholder='address....'
                  style={{ width: "100%" }} 
                  value={message }
                  onChange={(e)=>setMessage(e.target.value)} />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={AddContactDetailes}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete banner modal */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#080874" }}>
              <h3 style={{ color: "green" }}>WARNING !!</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='paragraphs'>Are you Sure?</p>
            <p className='paragraphs'>You want to delete this data?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button style={{ backgroundColor: '#080874' }} onClick={deleteContactdetailes}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* edit banner modal */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              Edit Contact Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

          {/* <div class="mt-3">
              <label  >Name</label>
              <div>
                <input type="text"
                  placeholder='name..'
                  style={{ width: "100%" }} 
                  value={name}
                  onChange={(e)=>setName(e.target.value)}/>
              </div>
            </div> */}

          <div class="mt-3">
              <label  >Phone Number</label>
              <div>
                <input type="text"
                  placeholder='phoneNumber..'
                  style={{ width: "100%" }} 
                  value={phoneNumber1}
                  onChange={(e) => setPhoneNumber1(e.target.value)}/>
              </div>
            </div>
            
            {/* <div>
              <label htmlFor="">phoneNumber2</label>
              <div>
                <input type="text"
                  placeholder='phoneNumber2......'
                  style={{ width: "100%" }} 
                  value={phoneNumber2}
                  onChange={(e)=>setPhoneNumber2(e.target.value)} />
              </div>
            </div> */}
           
            <div>
              <label htmlFor="">Email</label>
              <div>
                <input type="text"
                  placeholder='email....'
                  style={{ width: "100%" }} 
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)} />
              </div>
            </div>
            <br />
            <div>
              <label htmlFor="">Address</label>
              <div>
                <input type="text"
                  placeholder='address....'
                  style={{ width: "100%" }} 
                  value={message }
                  onChange={(e)=>setMessage(e.target.value)} />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={editContactDetailes}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <div>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Sl.No</th>
                {/* <th>Name</th> */}
                <th>Phone No</th>
                {/* <th>phoneNo2</th> */}
                <th>Email</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {data?.map((item, index) => {
                return ( 
                  <tr key={item._id}>
                  <td>{++index}.</td>
                {/* <td>{item.name}</td> */}
                <td>{item.phoneNumber1}</td>
                {/* <td>{item.phoneNumber2}</td> */}
                <td>{item.email}</td>
                <td>{item.message}</td>
                <td>
                
                <FaRegEdit style={{ cursor: 'pointer', color: "green", fontSize: "30px" }}
                        onClick={() => {
                          handleShow1();
                          setEdit(item)
                        }} /> /
                      <MdDelete style={{ cursor: 'pointer', color: "rgb(3, 146, 182)", fontSize: "30px" }}
                        onClick={() => {
                          handleShow2();
                          setEdit(item)
                        }}
                      />
                </td>
                </tr>
                 )
              })}

              
                </tbody>
          </Table>
        </div>

      </>) : (<></>)}

      {socialmedia ? (<>
        {/* <Row >
          <input type="search" placeholder="search here...." className='banner-input' />
          <div className="serch-icon">  <FaSearch /></div>
        </Row> */}

        <div>
          <h1 className='h1-style'>Social Media</h1>
 {data1.length ? (
  ""
) : (<Button className='add-buttons' onClick={handleShow}>
  +Add 
</Button>)}
          
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>Add Social Media </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            

            <div class="mt-3">
              {/* <label htmlFor="">WhatsApp URL</label> */}
              <div>
                <input type="text"
                  placeholder='Whatsapp url...'
                  style={{ width: "100%" }} 
                  id="input-border"
                  value={whatsappUrl}
                  onChange={(e)=>setWatsAppUrl(e.target.value)}/>
              </div>
            </div>
            <div class="mt-3">
              {/* <label htmlFor="">Facebook  URL</label> */}
              <div>
                <input type="text"
                  placeholder='Facebook url...'
                  style={{ width: "100%" }} 
                  id="input-border"
                  value={facebookUrl}
                  onChange={(e)=>setFacebookUrl(e.target.value)}/>
              </div>
            </div>
            <div class="mt-3">
              {/* <label htmlFor="">Instagram  URL</label> */}
              <div>
                <input type="text"
                  placeholder='Instagram url...'
                  style={{ width: "100%" }} 
                  id="input-border"
                  value={instagramUrl}
                  onChange={(e)=>setInstagramUrl(e.target.value)}/>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleShow}>
              Close
            </Button>

            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={AddSocialMediaDetailes}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete banner modal */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              <h3 style={{ color: "green" }}>WARNING !!</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='paragraphs'>Are you Sure?</p>
            <p className='paragraphs'>You want to delete this data?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={deleteSocialMedia}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* edit banner modal */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              Edit Social Media
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
         
          <div class="mt-3">
              {/* <label htmlFor="">WhatsApp URL</label> */}
              <div>
                <input type="text"
                  placeholder='Whatsapp url...'
                  style={{ width: "100%" }} 
                  id="input-border"
                  value={whatsappUrl}
                  onChange={(e)=>setWatsAppUrl(e.target.value)}/>
              </div>
            </div>
            <div class="mt-3">
              {/* <label htmlFor="">Facebook  URL</label> */}
              <div>
                <input type="text"
                  placeholder='Facebook url...'
                  style={{ width: "100%" }} 
                  id="input-border"
                  value={facebookUrl}
                  onChange={(e)=>setFacebookUrl(e.target.value)}/>
              </div>
            </div>
            <div class="mt-3">
              {/* <label htmlFor="">Instagram  URL</label> */}
              <div>
                <input type="text"
                  placeholder='Instagram url...'
                  style={{ width: "100%" }} 
                  id="input-border"
                  value={instagramUrl}
                  onChange={(e)=>setInstagramUrl(e.target.value)}/>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={editSocialMedia}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <div>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
               
                <th>Whatsapp URL</th>
                <th>Facebook URL</th>
                <th>Instagram URL</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {data1?.map((item, index) => {
            return (
              <tr key={item._id}>
               
                <td>{item.whatsappUrl}</td>
                <td>{item.facebookUrl}</td>
                <td> {item.instagramUrl}</td>
                <td>
                <FaRegEdit style={{ cursor: 'pointer', color: "green", fontSize: "30px" }}
                        onClick={() => {
                          handleShow1();
                          setEdit(item)
                        }} /> /
                      <MdDelete style={{ cursor: 'pointer', color: "rgb(3, 146, 182)", fontSize: "30px" }}
                        onClick={() => {
                          handleShow2();
                          setEdit(item)
                        }}
                      />

                </td>
              
              </tr> 
            )
          })}


    </tbody>
          </Table>
        </div>

      </>) : (<></>)}
    </div>
  )
}

export default ContactUs