import React, { useState, useEffect } from 'react'
import { Row, Modal } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { FaSearch } from "react-icons/fa";
import './Admin.css'
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoWarningOutline } from "react-icons/io5";
import axios from 'axios';

const Banner = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);



  const [data, setdata] = useState([]);
  const [bannerText, setBannerText] = useState('');
  const [bannerimg, setBannerImg] = useState("")

 
  // Post Method
  const AddBanner = async () => {
    const formdata = new FormData();
    formdata.append('bannerimg', bannerimg); 
    formdata.append('bannertext', bannerText);

    //All Feilds are required
    if (!bannerimg || !bannerText.trim()) {
      alert('All fields are required');
      return; 
    }
    try {

      const config = {
        url: "banner/postbanner",
        method: "post",
        baseURL: "https://talharconstructions.in/api/",
        Headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };

      axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose(); 
          getBannerData();
          setBannerText('');
          setBannerImg('');
        }
      });

    } catch (error) {
      console.error('Error adding banner:', error);
      alert('Failed to add banner');
    }
  };

   // Get Method
   const getBannerData = async () => {
    
    try {
      const res = await axios.get('https://talharconstructions.in/api/banner/getbanner');
      if (res.status === 200) {
        setdata(res.data.success);
      }
    } catch (error) {
      console.error('Error fetching banners:', error);
      alert('Failed to fetch banners');
    }
  };

  useEffect(() => {
    getBannerData()
    
  }, [])

  //Delete Method
  const [edit, setEdit] = useState({})
  console.log(edit, "sditeeee")

  const deletData = async (_id) => {
    try {
      let res = await axios.delete(`https://talharconstructions.in/api/banner/deletebanner/${edit?._id}`)
      if (res.status === 200) {
        alert("Deleted Successfully")
        handleClose2()
        getBannerData([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  //Update Method
  useEffect(() =>{
    if(edit){
      setBannerText(edit?.bannertext || "")
    }
  }, [])
  const editBanner = async () => {
    const formdata = new FormData()
    formdata.append('bannerimg', bannerimg)
    formdata.append('bannertext', bannerText)
   //All Feilds are required
    if (!bannerimg && !bannerText.trim()) {
      alert('Please Update any one field');
      return; 
    }
    try {
      const config = {
        url: `/updatebanner/${edit?._id}`,
        method: "put",
        baseURL: "https://talharconstructions.in/api/banner",
        Headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config)
      if (res.status === 200) {
        alert(res.data.success);
        handleClose1();
        getBannerData();
        // after updated the inputfields will be clear
        setBannerText('');
        setBannerImg('');
        
      }

    } catch (error) {
      console.error('Error adding banner:', error);
      alert('Failed to add banner');
    }
  }

  return (
    <div>
      {/* <Row >
        <input type="search"
          placeholder="search here...." className='banner-input' />
        <div className='serch-icon'>  <FaSearch /></div>
      </Row> */}

      <div>
        <h1 className='h1-style' >Banner</h1>
        <Button className='add-buttons'
          onClick={handleShow}>
          +Add
        </Button>
      </div>
      <Table striped bordered hover>
      <thead  className="text-center ">
        <tr>
          <th>Sl.No</th>
          <th>Banner Img</th>
          <th>Banner Text</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => (
          <tr key={item._id}>
            <td>{++index}.</td>
            <td>
              <div>
                <img 
                  src={`https://talharconstructions.in/Bannerimage/${item?.bannerimg}`}
                  alt="Bannerimage"
                  style={{ width: "100px", height: "100px" }} 
                />
              </div>
            </td>
           
           <td>{item.bannertext}</td>
          
            <td>
             <div className='d-flex gap-2'>
             <FaRegEdit 
                style={{ cursor: 'pointer', color: "green", fontSize: "30px" }}
                onClick={() => {
                  handleShow1();
                  setEdit(item);
                }} 
              /> /
              <MdDelete 
                style={{ cursor: 'pointer', color: "rgb(3, 146, 182)", fontSize: "30px" }}
                onClick={() => {
                  handleShow2();
                  setEdit(item);
                }}
              />
             </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>

      {/* Add banner model */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
            +Add Banner
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className='mb-2'>
            <label htmlFor="inputGroupFile05">Banner Img</label>
            <input type="file"
              class="form-control"
              id="inputGroupFile03"
              aria-describedby="inputGroupFileAddon05"
              aria-label="Upload"
              accept="image/*"
              onChange={(e) => setBannerImg(e.target.files[0])} />
          </div>

          <div>
            <label htmlFor="">Banner Text</label>
            <div>
              <input type="text"
                placeholder='Banner Text....'
                style={{ width: "100%" }}
                value={bannerText}
                onChange={(e) => setBannerText(e.target.value)} />
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={AddBanner}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete banner modal */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
            <h3 style={{ color: "green" }}>WARNING <IoWarningOutline /></h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='paragraphs'>Are you Sure?</p>
          <p className='paragraphs'>You want to delete this data?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={deletData}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* edit banner modal */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
            Edit Banner
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className='mb-2'>
            <label htmlFor="inputGroupFileAddon06">Banner Img</label>
            <input type="file"
              class="form-control"
              id="inputGroupFile03"
              aria-describedby="inputGroupFileAddon06"
              aria-label="Upload"
              accept="image/*"
              onChange={(e) => setBannerImg(e.target.files[0])} />
          </div>
          <div>
            <label htmlFor="">Banner Text</label>
            <div>
              <input type="text"
                placeholder='Banner Text....'
                style={{ width: "100%" }}
                value={bannerText}
                onChange={(e) => setBannerText(e.target.value)} />
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={editBanner}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Banner