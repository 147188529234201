import React,{useState,useEffect} from 'react';
import { Container, Nav, Navbar, Offcanvas, Col, Row } from 'react-bootstrap';
import "../Styles/Header.css";
import { LuPhoneCall } from 'react-icons/lu'; // Phone icon
import { MdEmail } from 'react-icons/md'; // Email icon
import { FiMapPin } from 'react-icons/fi'; // Location icon
import { FiGlobe } from 'react-icons/fi'; // Website icon
import axios from 'axios'


function Header() {

  
  const [data, setdata] = useState([]);
 console.log(data,"sdjfduhgiudh")
 const getContactDetailes = async () => {
   try {
     const res = await axios.get('https://talharconstructions.in/api/contactus/getcontactusdetails');
     console.log(res,"arpjeqpiffffff");
     if (res.status === 200) {
       setdata(res.data.success);
     }
   }
    catch (error) {
     console.error('Error fetching banners:', error);
     alert('Failed to fetch banners');
   }
 };

 useEffect(() => {
  getContactDetailes()
 }, [])
 console.log('data',data);

  return (
    <>
   <header>
   <div className="header-container">
    <a href="/" style={{color:"unset", textDecoration:"none"}}>
    <img src="../Assets/talharhd-logo.jpeg" alt="Logo" className="logo" />
    </a>

  <div className="contact-item-wrapper">
    {data?.map((item, index) => (
      <React.Fragment key={index}>
        <div className="contact-info">
          <LuPhoneCall  style={{color:"#4798bd", fontSize:"30px"}} />{" "}
          <div className="contact-text">
            <span>{item?.phoneNumber1?.split('')}</span>
          </div>
        </div>

        <div className="contact-info">
          <MdEmail style={{color:"#4798bd", fontSize:"20px"}} />{" "}
          <span className="contact-text">{item?.email}</span>
        </div>

        <div className="contact-info ">
          <FiMapPin style={{color:"#4798bd", fontSize:"40px"}} />{" "}
          <div className="contact-text">
            <ul>
              <li className="contact-text">
                <span>{item?.message}</span>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    ))}

    <div className="scanner">
      <img src="../Assets/addscanner.jpg" alt="Scanner" />
    </div>
  </div>
</div>

</header>

    <div
        className="headers"
        style={{
          position: "sticky",
          top: "0px",
          zIndex: "999",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          backgroundColor: "#316B81",
        }}
      >
        {["xl"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
          >
            <Container fluid style={{ padding:"0px 30px"}}>

              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton />
                <Offcanvas.Body>
                  <Nav>
                    <Nav.Link href="/" className="nav-link">
                      <b>HOME</b>
                    </Nav.Link>
                    <Nav.Link href="/aboutus" className="nav-link">
                      <b> ABOUT US</b>
                    </Nav.Link>
                    <Nav.Link href="/service" className="nav-link">
                      <b>SERVICE</b>
                    </Nav.Link>
                    <Nav.Link href="/gallery" className="nav-link">
                      <b>GALLERY</b>
                    </Nav.Link>
                    <Nav.Link href="/packages" className="nav-link">
                      <b>PACKAGES</b>
                    </Nav.Link>
                    <Nav.Link href="/contact" className="nav-link">
                      <b> CONTACT US</b>
                    </Nav.Link>
                    
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>

        ))}
      </div>
    </>
  );
}

export default Header;
